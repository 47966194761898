import {NgModule} from '@angular/core';
import {PreloadAllModules, Route, RouterModule,} from '@angular/router';
import {AuthGuard} from '@auth0/auth0-angular';
import {LearningCatalogTitle, SoftSkillsHubTitle} from '@constants';
import {Roles} from '@enums';
import {rolesGuard} from '@guards/rolesGuard';

//TODO: Redesign the routes across all modules, use a single pattern.
export const appRoutes: Route[] = [
    {
        path: '',
        title: LearningCatalogTitle,
        canActivate: [AuthGuard, rolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member]
        },
        loadChildren: () => import('./pages/learning-catalog/learning-catalog.module').then(m => m.LearningCatalogModule),
    },
    {
        path: '',
        title: SoftSkillsHubTitle,
        canActivate: [AuthGuard, rolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member]
        },
        loadChildren: () => import('./pages/soft-skills-hub/soft-skills-hub.module').then(m => m.SoftSkillsHubModule),
    },
    {
        path: 'courses/:journeyId/:journeySlug/:lessonId/:lessonSlug',
        canActivate: [AuthGuard, rolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member],
            isAdminPage: false
        },
        loadChildren: () => import('./pages/learning-content/learning-content.module').then(m => m.LearningContentModule),
    },
    {
        path: 'courses/:journeyId/:journeySlug',
        canActivate: [AuthGuard, rolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member]
        },
        loadChildren: () => import('./pages/journey-overview/journey-overview.module').then(m => m.JourneyOverviewModule),
    },
    {
        path: 'soft-skills-hub/:journeyId/:journeySlug/:lessonId/:lessonSlug',
        canActivate: [AuthGuard, rolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member],
            isAdminPage: false
        },
        loadChildren: () => import('./pages/learning-content/learning-content.module').then(m => m.LearningContentModule),
    },
    {
        path: 'soft-skills-hub/:journeyId/:journeySlug',
        canActivate: [AuthGuard, rolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member]
        },
        loadChildren: () => import('./pages/journey-overview/journey-overview.module').then(m => m.JourneyOverviewModule),
    },
    {
        path: 'admin/journey-preview',
        pathMatch: 'prefix',
        canActivate: [AuthGuard, rolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor],
            isAdminPage: true
        },
        loadChildren: () => import('./pages/journey-overview/journey-overview.module').then(m => m.JourneyOverviewModule),
    },
    {
        path: 'admin',
        pathMatch: 'prefix',
        canActivate: [AuthGuard, rolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor],
            isAdminPage: true,
            isAdminLessonPreviewPage: true
        },
        loadChildren: () => import('./pages/learning-content/learning-content.module').then(m => m.LearningContentModule),
    },
    {
        path: 'error',
        canActivate: [AuthGuard, rolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member],
            isAdminPage: false
        },
        loadChildren: () => import('./pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
    },
    {
        path: '**',
        redirectTo: 'error/not-found',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            bindToComponentInputs: true,
            scrollPositionRestoration: 'top'
        }),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
