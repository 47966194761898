/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import environment from '@environment';

export interface IStandaloneLessonEvent {
    action: string;
    email: string;
    micro_learning: string;
}

@Injectable()
export class AnalyticsService {
    private readonly _zapierUrl: string;

    constructor() {
        this._zapierUrl = environment.defaultConfiguration.auth.zapierUrl;
    }

    /**
     * Sends a standalone lesson activity event to Zapier using Fetch API with 'no-cors' mode
     * @param email User's email address
     * @param title Title of the micro-learning content
     * @returns Observable that completes when the request is sent
     */
    public sendStandaloneLessonActivityEvent(email: string, title: string): Observable<void> {
        return new Observable<void>(observer => {
            const endpoint = `${this._zapierUrl}/hooks/catch/14966491/2bxen57`; // Ensure this URL is correct
            const payload: IStandaloneLessonEvent = {
                action: 'social_skills_micro_learning_view', // Corrected 'mico' to 'micro' if it was a typo
                email,
                micro_learning: title,
            };

            fetch(endpoint, {
                method: 'POST',
                mode: 'no-cors', // This disables CORS, but response will be opaque
                headers: {
                    skipErrorHandling: 'true',
                    skip: 'true'
                },
                body: JSON.stringify(payload),
            })
                .then(() => {
                    observer.next();
                    observer.complete();
                })
                .catch(error => {
                    console.error('Error triggering Zapier webhook:', error);
                    observer.error(error);
                    observer.complete();
                });
        });
    }
}
