/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Subject} from 'rxjs';
import {IRightSideModalInput} from '@interfaces';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RightSideModalService {
    public visibility = new Subject<IRightSideModalInput>();
    public banner = new Subject<string>();
    public closed = new Subject<void>();
    public modalOpen: boolean;

    public open(modalInput: IRightSideModalInput): void {
        modalInput.isVisible = true;
        this.visibility.next(modalInput);
        this.modalOpen = true;
    }

    public close(): void {
        this.visibility.next(null);
        this.modalOpen = false;
        this.closed.next();
    }

    public updateBanner(banner: string): void {
        this.banner.next(banner);
    }
}
