/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

type httpRequestMethod = 'get' | 'post' | 'patch' | 'delete';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private readonly http: HttpClient) {
  }

  public get(endpoint: string, options?: any): Observable<any> {
    return this.http.get(
        `${endpoint}`,
        options
    );
  }

    public post(endpoint: string, payload?: any, options?: any): Observable<any> {
    return this.http.post(
        `${endpoint}`,
        payload,
        options
    );
  }

  public put(endpoint: string, payload?: any, options?: any): Observable<any> {
    return this.http.put(
        `${endpoint}`,
        payload,
        options
    );
  }

  public standardHttpRequest<T>(
    method: httpRequestMethod,
    endpoint: string,
    param: any = {}
  ) : Observable<T> {
    return new Observable(observer => {
        const request = this[method](endpoint, param);

        request.subscribe({
            next: (res: T) => {
                observer.next(res);
                observer.complete();
            },
            error: err => {
                observer.error(err);
                observer.complete();
            }
        });
    });
  }
}
