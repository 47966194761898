/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {datadogRum} from '@datadog/browser-rum';
import environment from '@environment';
import { ENVIRONMENTS } from '@constants';

@Injectable({
    providedIn: 'root'
})
export class DatadogService {

    public initiate(): void {
        const { auth, production, applicationVersion } = environment.defaultConfiguration;
        const shouldInitiateDatadog = [ENVIRONMENTS.QA, ENVIRONMENTS.E2E, ENVIRONMENTS.CICD, ENVIRONMENTS.NEXT].includes(auth.envName) || production;
        const excludedErrorMessages = ['CanceledError', 'Beacon', 'csp_violation'];

       if (shouldInitiateDatadog) {
            datadogRum.init({
                applicationId: 'a6b38c32-64ff-4590-b6e7-7dd60f229921',
                clientToken: 'pubc75f34cdde5743d536f52ff63cc1c353',
                site: 'datadoghq.eu',
                service: 'aihr-lms-spa',
                env: auth.envName,
                version: applicationVersion,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                trackFrustrations: true,
                defaultPrivacyLevel: 'allow',
                useCrossSiteSessionCookie: true,
                trackSessionAcrossSubdomains: true,
                telemetrySampleRate: 100,
                beforeSend: event => {
                    if (event.type === 'error') {
                        const errorMessage = event.error?.message || '';

                        if (excludedErrorMessages.some(excludedMessage => errorMessage.includes(excludedMessage))) {
                            return false; // Prevent the event from being logged
                        }
                    }

                    return true
                },
            });

            datadogRum.startSessionReplayRecording();
        }
    }
}