/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

export const DefaultMaxDurationInHours = 30;
export const MaxDurationInHours = 1000;
export const DefaultDebounceTime = 500;
export const DefaultSortingOption = {fieldName: 'LearningProgresses', orderByAsc: false};
export const SearchPageSize = 10;