/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import environment from '@environment';
import {ApiService} from '@services/core';
import {
    IAssessmentInitOptions,
    ILearnosityPreviousAttempts,
    ILearnosityRequest,
    IReportInitOptions
} from '@learning-content/interfaces';

@Injectable()
export class AssessmentService {
    private readonly lmsAPIUri: string;

    constructor(private readonly _apiService: ApiService) {
        this.lmsAPIUri = environment.defaultConfiguration.auth.lmsApiUri;
    }

    public getActivityConfig(payload: ILearnosityRequest): Observable<IAssessmentInitOptions | IReportInitOptions> {
        const endpoint = `${this.lmsAPIUri}/api/v1/assess`;
        return this._apiService.post(endpoint, payload);
    }

    public getPreviousAttempts(activityId: string): Observable<ILearnosityPreviousAttempts[]> {
        const endpoint = `${this.lmsAPIUri}/api/v1/assess/${activityId}/scores`;
        return this._apiService.get(endpoint);
    }

    public claimCertificate(rootJourneyId: number): Observable<void> {
        const endpoint = `${this.lmsAPIUri}/api/v1/assess/claim-certificate/${rootJourneyId}`;
        return this._apiService.post(endpoint);
    }
}