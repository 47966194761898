/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import environment from '@environment';
import {Observable} from 'rxjs';
import {IFilterSchema, ISortingOptions} from '@learning-catalog/interfaces';
import {HttpParams} from '@angular/common/http';
import { IJourneyOverview } from '@interfaces';
import { ApiService } from '@services/core';
import { DefaultMaxDurationInHours, DefaultSortingOption, MaxDurationInHours, SearchPageSize } from '@learning-catalog/constants';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    private readonly lmsAPIUri: string;

    constructor(private readonly _apiService: ApiService) {
        this.lmsAPIUri = environment.defaultConfiguration.auth.lmsApiUri;
    }

    public getFilters(catalogType: string): Observable<IFilterSchema[]> {
        const endpoint = `${this.lmsAPIUri}/api/v1/${catalogType}/filters`;
        return this._apiService.standardHttpRequest('get', endpoint);
    }

    public search(
        catalogType: string,
        categories: number[] = [],
        labels: number[] = [],
        statuses: number[] = [],
        difficulties: number[] = [],
        maxDurationInHours: number = DefaultMaxDurationInHours,
        sortingOptions: ISortingOptions = DefaultSortingOption,
        searchTerm = '',
        page = 1,
        pageSize = SearchPageSize,
    ): Observable<IJourneyOverview[]> {
        let params = new HttpParams()
            .set('SortingOptions.FieldName', sortingOptions.fieldName)
            .set('SortingOptions.OrderByAsc', sortingOptions.orderByAsc)
            .set('Page', page)
            .set('PageSize', pageSize);

        if (searchTerm.length){
            params = params.set('SearchTerm', searchTerm)
        }

        const paramMappings = [
            {key: 'Filter.CategoryIds', value: categories},
            {key: 'Filter.LabelIds', value: labels},
            {key: 'Filter.Progress', value: statuses},
            {key: 'Filter.DifficultyLevels', value: difficulties},
        ];

        paramMappings.forEach(mapping => {
            mapping.value?.forEach(item => params = params.append(mapping.key, item.toString()));
        });

        if (maxDurationInHours < DefaultMaxDurationInHours) {
            params = params.set('Filter.MaxDurationInMinutes', this.hoursToMinutesString(maxDurationInHours));
        } else {
            params = params.set('Filter.MaxDurationInMinutes', this.hoursToMinutesString(MaxDurationInHours));
        }

        const endpoint = `${this.lmsAPIUri}/api/v1/${catalogType}/search`;
        return this._apiService.standardHttpRequest('get', endpoint, {params});
    }

    private hoursToMinutesString(hours: number): string {
        return (hours * 60).toString();
    }
}
