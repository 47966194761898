/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {debounceTime, first, Observable, Subscriber} from 'rxjs';
import environment from '@environment';
import {Store} from '@ngrx/store';
import {AuthService} from '@auth0/auth0-angular';
import {HttpHeaders, HttpStatusCode} from '@angular/common/http';
import {ILoadingState, startLoading} from '@store/loading';
import {ApiService, AuthenticationService, FeatureFlagService, LocalStorageService} from '@services/core';

@Injectable()
export class OnboardingService {
    private readonly _resourcesUrl: string;
    private readonly _userUri: string;
    private readonly _userApiVersion: string;
    private readonly _minimumOnboardingPercentage = 9;
    private readonly _numberOfDaysForOnboarding = 60;
    private readonly _shouldShowOnboardingKey = 'shouldShowOnboarding';

    constructor(
        private readonly _apiService: ApiService,
        private readonly _store: Store<{ state: ILoadingState }>,
        private readonly _authService: AuthService,
        private readonly _authenticationService: AuthenticationService,
        private readonly _localStorageService: LocalStorageService,
        private readonly _featureFlagService: FeatureFlagService
    ) {
        this._resourcesUrl = environment.defaultConfiguration.auth.resourcesUrl;
        this._userUri = environment.defaultConfiguration.auth.usersApiUri;
        this._userApiVersion = environment.defaultConfiguration.auth.usersApiVersion;
    }

    /**
     * Calculates the onboarding threshold date based on the number of days allowed for onboarding.
     */
    private get onboardingThresholdDate(): Date {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - this._numberOfDaysForOnboarding);
        return currentDate;
    }

    public redirectToOnboardingPage(): void {
        this._store.dispatch(startLoading());
        const endpoint = `${this._resourcesUrl}/onboarding/`;
        this._featureFlagService.goToExternalUrlAndFlush(endpoint);
    }

    public getOnboardingProgress(): Observable<number> {
        return new Observable<number>(subscriber => {
            this.shouldShowOnboardingComponent().pipe(first(), debounceTime(200)).subscribe(result => {
                if (result) {
                    this.getProgress(subscriber);
                }
            });
        });
    }

    public shouldShowOnboardingComponent(): Observable<boolean> {
        return new Observable<boolean>(subscriber => {
            const cachedResult = this._localStorageService.getItem(this._shouldShowOnboardingKey);
            if (cachedResult !== null && cachedResult?.toLowerCase() === 'false') {
                subscriber.next(false);
                subscriber.complete();
                return;
            }
            const endpoint = `${this._userUri}/user/me?api-version=${this._userApiVersion}`;
            this._apiService.get(endpoint, {
                headers: {
                    skipErrorHandling: 'true',
                    skip: 'true'
                }
            }).subscribe({
                next: (response: { result: { createdAt: string } }) => {
                    const user = response?.result;
                    const hasRequiredRoles = this._authenticationService.isMember;

                    const userCreationDate = new Date(user?.createdAt);
                    const isWithinOnboardingPeriod = userCreationDate > this.onboardingThresholdDate;
                    const result = hasRequiredRoles && isWithinOnboardingPeriod;

                    this._localStorageService.setItem(this._shouldShowOnboardingKey, result?.toString());

                    subscriber.next(result);
                    subscriber.complete();
                },
                error: error => {
                    subscriber.error(error);
                }
            });
        });
    }

    private getProgress(subscriber: Subscriber<number>): void {
        this._authService.user$.subscribe(user => {
            const endpoint = `${this._resourcesUrl}/wp-json/custom/v1/onboarding-completion/${user.bnw_user_id}`;
            const headers = new HttpHeaders().set('skipErrorHandling', 'true');
            this._apiService.get(endpoint, {headers}).subscribe({
                next: (response: { total_completion_percentage: number }) => {
                    subscriber.next(Number(response?.total_completion_percentage ?? this._minimumOnboardingPercentage));
                    subscriber.complete();
                },
                error: error => {
                    if (error.status === HttpStatusCode.NotFound) {
                        subscriber.next(this._minimumOnboardingPercentage);
                        subscriber.complete();
                    } else {
                        subscriber.error(error);
                    }
                }
            });
        });
    }
}
