/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import environment from '@environment';
import {ApiService} from '@services/core';
import {HttpHeaders, HttpResponse} from '@angular/common/http';
import {IClientLessonDTO, ILesson} from '@interfaces';

@Injectable()
export class LessonService {
    private readonly _lmsAPIUri: string;

    constructor(private readonly _apiService: ApiService) {
        this._lmsAPIUri = environment.defaultConfiguration.auth.lmsApiUri;
    }

    public getByIdAdmin(id: number): Observable<ILesson> {
        const endpoint = `${this._lmsAPIUri}/admin/api/v1/lesson/${id}`;
        return this._apiService.standardHttpRequest('get', endpoint);
    }

    public getByIdClient(rootJourneyId: number, lessonId: number): Observable<ILesson> {
        const endpoint = `${this._lmsAPIUri}/api/v1/journey/${rootJourneyId}/${lessonId}`;
        return this._apiService.standardHttpRequest('get', endpoint);
    }

    public downloadFile(rootJourneyId: number, fileId: string): Observable<HttpResponse<Blob>> {
        const endpoint = `${this._lmsAPIUri}/api/v1/file?journeyId=${rootJourneyId}&fileId=${fileId}`;
        const headers = new HttpHeaders().set('skipErrorHandling', 'true');
        return this._apiService.get(endpoint, {
            observe: 'response',
            responseType: 'blob',
            headers
        });
    }

    public getNextLesson(rootJourneyId: number, lessonId?: number): Observable<IClientLessonDTO>  {
        let endpoint = `${this._lmsAPIUri}/api/v1/journey/${rootJourneyId}/next-lesson`;
        endpoint += lessonId ? `/${lessonId}` : '';
        return this._apiService.get(endpoint);
    }

    public completeLesson(rootJourneyId: number, lessonParentJourneyId: number, lessonId: number): Observable<void> {
        const endpoint = `${this._lmsAPIUri}/api/v1/journey/${rootJourneyId}/${lessonParentJourneyId}/${lessonId}/complete`;
        return this._apiService.put(endpoint);
    }

    public getById(id: number): Observable<ILesson> {
        const endpoint = `${this._lmsAPIUri}/api/v1/lesson/${id}`;
        return this._apiService.standardHttpRequest('get', endpoint);
    }
}
