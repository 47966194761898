/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {AuthService, LogoutOptions} from '@auth0/auth0-angular';
import environment from '@environment';
import { learningCatalogStoreKey, softSkillsHubStoreKey } from '@constants';
import {Store} from '@ngrx/store';
import {first, Observable, of, Subscriber} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { LocalStorageService, NotificationService } from '@services/core';
import { getRoles, IRolesState, setRoles } from '@store/roles';
import { Roles } from '@enums';


export interface IDecodedToken {
    exp: number;
    iat?: number;
    tokenRolesKey?: string[];
    bnw_user_id?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    public isMember: boolean;
    public user$ = this._authService.user$;

    private decodedToken: IDecodedToken;

private readonly identityStorageKey = 'AIHR.User.AccessToken';
private readonly tokenRolesKey = 'AIHR/Roles';
private readonly tokenExpirationKey = 'AIHR.TokenExpiration';

    constructor(
        private readonly _authService: AuthService,
        private readonly _notificationService: NotificationService,
        private readonly _store: Store<{ state: IRolesState }>,
        private readonly _storageService: LocalStorageService
    ) {
    }

    public getCachedAccessToken(): string {
        const cachedToken = this._storageService.getItem(this.identityStorageKey);
        return cachedToken ? cachedToken.replace(/["']/g, '') : '';
    }

    public logout(): void {
        this._storageService.removeItem(softSkillsHubStoreKey);
        this._storageService.removeItem(learningCatalogStoreKey);
        this._storageService.removeItem(this.identityStorageKey);
        this._storageService.clear();
        const options: LogoutOptions = {
            logoutParams: {returnTo: environment.defaultConfiguration.auth.appUri},
        };
        this._authService.logout(options);
    }

    public userHasRequiredRole(roles: number[]): Observable<boolean> {
        return this._store.select(getRoles).pipe(
            first(),
            map(storeRoles => {
                // Convert numeric role values to their corresponding role names
                const requiredRoleNames = roles.map(role => Roles[role]);
                let searchRoles = storeRoles;

                // In case the user logged in for the first time and the roles are not yet set in the store
                if (storeRoles.length === 0) {
                    this.decodedToken = this.decodeJwtToken(this.getCachedAccessToken());
                    searchRoles = this.decodedToken[this.tokenRolesKey];
                }

                this.isMember = searchRoles.includes(Roles[Roles.Member]);

                // Check if any required role is present in the user's roles
                return requiredRoleNames.some(role => searchRoles.includes(role));
            }),
            catchError(err => {
                this._notificationService.error(err.message);
                return of(false);
            })
        );
    }

    public isUserAuthenticated(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            const cachedToken = this.getCachedAccessToken();
            const tokenExpiration = this.getCachedTokenExpiration();
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

            if (cachedToken && tokenExpiration && currentTime < tokenExpiration) {
                this.decodedToken = this.decodeJwtToken(cachedToken);
                this._store.dispatch(setRoles({ roles: this.decodedToken[this.tokenRolesKey] }));
                observer.next(true);
                observer.complete();
                return;
            }

            this.checkAuthentication(observer);
        });
    }

    public getDecodedToken(): IDecodedToken {
        const cachedToken = this.getCachedAccessToken();

        return this.decodeJwtToken(cachedToken);
    }

    public getSilentToken(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.fetchTokenSilently(observer);
        });
    }

    private checkAuthentication(observer: Subscriber<boolean>): void {
        this._authService.isAuthenticated$.pipe(first()).subscribe({
            next: isAuthenticated => {
                if (!isAuthenticated) {
                    observer.next(false);
                    observer.complete();
                    return;
                }
                this.fetchUserAndSetToken(observer);
            },
            error: err => this.handleError(observer, err)
        });
    }

    private fetchUserAndSetToken(observer: Subscriber<boolean>): void {
        this._authService.user$.pipe(first()).subscribe({
            next: user => {
                const { 'AIHR/Roles': roles } = user;
                this._store.dispatch(setRoles({ roles }));
                this.fetchTokenSilently(observer);
            },
            error: err => this.handleError(observer, err)
        });
    }

    private fetchTokenSilently(observer: Subscriber<boolean>): void {
        this._authService.getAccessTokenSilently().pipe(first()).subscribe({
            next: token => {
                this.storeTokenAndSetExpiration(observer, token);
            },
            error: err => this.handleError(observer, err)
        });
    }

    private storeTokenAndSetExpiration(observer: Subscriber<boolean>, token: string): void {
        this._storageService.setItem(this.identityStorageKey, token);
        const decodedToken = this.decodeJwtToken(token);
        this.setTokenExpiration(decodedToken.exp);
        observer.next(true);
        observer.complete();
    }

    private handleError(observer: Subscriber<boolean>, err: Error): void {
        this._notificationService.error(err.message);
        observer.next(false);
        observer.complete();
    }

    private getCachedTokenExpiration(): number {
        return parseInt(this._storageService.getItem(this.tokenExpirationKey), 10);
    }

    private setTokenExpiration(expiration: number): void {
        this._storageService.setItem(this.tokenExpirationKey, expiration.toString());
    }

    private decodeJwtToken(token: string): IDecodedToken {
        const payload = token.split('.')[1];
        return JSON.parse(atob(payload));
    }
}
